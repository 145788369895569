import React, { useCallback, useMemo, useState } from "react"
import dayjs from "dayjs"
import { saveAs } from "file-saver"
import useQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import { useTranslation } from "react-i18next"
import { useEstimatesList } from "../../hooks/estimates"
import classNames from "classnames"
import Icon from "../../components/Icon"
import Layout from "../../components/Layout"
import { DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown } from "reactstrap"
import Separator from "../../components/Separator/Separator"
import Table from "../../components/Table/Table"
import MeasureContext from "../../components/MeasureContext/MeasureContext"
import EmptyFilterImage from "../../assets/icons/empty_filter.svg"
import EmptyImage from "../../assets/icons/empty_page.svg"
import Button from "../../components/Button"
import Pagination from "../../components/Pagination/Pagination"
import WpInput from "../../components/WpInput"
import CreateEstimateModal from "../../components/CreateEstimateModal"
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal/ConfirmDeleteModal"
import Loader from "../../components/Loader/Loader"
import { notifyError } from "../../utils"
import CreateEstimateTemplateModal from "../../components/CreateEstimateTemplateModal/CreateEstimateTemplateModal"
import LongBorder from "../../components/LongBorder"
import S from "./EstimatesList.module.scss"
import WpLink from "../../components/WpLink"
import useModalTriggerQuota from "../../hooks/useModalTriggerQuota"
import { getCurrencyFormatter } from "../../hooks/useCurrencyFormatter"
import useWpHistoryMethods from "../../hooks/useWpHistoryMethods"
import { PDF_BASE_URL } from "../../consts"
import { useAuthUser } from "use-eazy-auth"
import useCurrentOrganization from "../../hooks/useCurrentOrganization"
import Dropdown from "../../components/Dropdown"
import { CustomerSelector } from "../../components/CustomerSelector/CustomerSelector"
import { EstimateLandmarks } from "../../components/EstimateLandmarks"
import { DropdownItemCreateInteraction } from "../../components/DropdownItemCreateInteraction"
import { TagCollection } from "../../components/TagCollection"

export default function EstimatesList() {
  const history = useWpHistoryMethods()
  const { token } = useAuthUser()
  const org = useCurrentOrganization()

  const [
    { title, code, customer, status, archived, kind },
    setQueryParams,
    {
      title: debTitle,
      code: debCode,
      tag: debTag,
      page: debPage,
      customer: debCustomer,
      status: debStatus,
      archived: debArchived,
      kind: debKind,
    },
    setDebouncedQueryParams,
  ] = useQueryParams({
    page: {
      encode: (intValue) => intValue.toString(10),
      decode: (strValue) => (strValue ? parseInt(strValue, 10) : 1),
    },
    title: {
      encode: (value) => value || "",
      decode: (rawvalue) => rawvalue || "",
    },
    code: {
      encode: (value) => value || "",
      decode: (rawvalue) => rawvalue || "",
    },
    tag: {
      encode: (value) => value || "",
      decode: (rawvalue) => rawvalue || "",
    },
    status: {
      encode: (value) => value || "",
      decode: (rawvalue) => rawvalue || "all",
    },
    kind: {
      encode: (value) => value || "",
      decode: (rawvalue) => rawvalue || "all",
    },
    archived: {
      encode: (value) => value || "",
      decode: (rawvalue) => rawvalue || "active",
    },
    customer: {
      encode: (value) => (value || "").toString(),
      decode: (rawvalue) => (rawvalue ? parseInt(rawvalue, 10) : null),
    },
  })

  const estimatesFilters = useMemo(() => {
    let archived = undefined
    let status = debStatus
    let kind = debKind
    if (debStatus === "all") {
      status = undefined
    }
    if (debArchived === "active") {
      archived = "false"
    } else if (debArchived === "archived") {
      archived = "true"
    }
    if (debKind === "all") {
      kind = undefined
    }
    return {
      page: debPage,
      code: debCode,
      title: debTitle,
      tag: debTag,
      archived,
      drafting_state: status,
      customer: debCustomer,
      kind,
    }
  }, [debStatus, debKind, debArchived, debPage, debCode, debTitle, debTag, debCustomer])

  const { t } = useTranslation(["translation", "field", "enums", "action"])

  const [createModal, createModalActions] = useModalTriggerQuota()
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false)

  const [createTemplateModal, createTemplateModalActions] = useModalTriggerQuota()

  const [toDelete, setToDelete] = useState(null)

  const onDelete = useCallback(() => {
    setConfirmModalOpen(false)
  }, [])

  const [
    { estimates, count, fullCount, pageCount, hasNext, hasPrev, loading, exportingPdfs },
    { createEstimate, deleteEstimate, updateEstimate, cloneEstimate, exportPDF, instanceEstimatTemplate, run: reload },
  ] = useEstimatesList(estimatesFilters)

  const onEstimateCreation = useCallback(
    (estimateData) => {
      if (estimateData.cloneId) {
        const { cloneId, ...cloneData } = estimateData
        return cloneEstimate
          .onSuccess((estimate) => {
            history.push(`/estimates/${estimate.id}/`)
          })
          .asPromise(cloneId, cloneData)
      }
      return createEstimate
        .onSuccess((estimate) => {
          history.push(`/estimates/${estimate.id}/`)
        })
        .asPromise(estimateData)
    },
    [cloneEstimate, createEstimate, history]
  )

  const onEstimateTemplateCreation = useCallback(
    (estimateData) => {
      const { cloneId, ...cloneData } = estimateData
      return instanceEstimatTemplate
        .onSuccess((estimate) => {
          history.push(`/estimates/${estimate.id}/`)
        })
        .asPromise(cloneId, cloneData)
    },
    [history, instanceEstimatTemplate]
  )

  const onEstimateDeletion = useCallback(() => {
    return deleteEstimate
      .onFailure(notifyError)
      .onSuccess(() => {
        setToDelete(null)
        reload(estimatesFilters)
      })
      .run(toDelete.id)
  }, [deleteEstimate, estimatesFilters, reload, toDelete?.id])

  const onEstimateArchive = useCallback(
    (toArchive) => {
      updateEstimate
        .onFailure(notifyError)
        .onSuccess(() => {
          reload(estimatesFilters)
        })
        .run(toArchive.id, {
          archived: true,
        })
    },
    [estimatesFilters, reload, updateEstimate]
  )

  const onEstimateRestore = useCallback(
    (toRestore) => {
      updateEstimate
        .onFailure(notifyError)
        .onSuccess(() => {
          reload(estimatesFilters)
        })
        .run(toRestore.id, {
          archived: false,
        })
    },
    [estimatesFilters, reload, updateEstimate]
  )

  const onEstimateLock = useCallback(
    (toLock) => {
      updateEstimate
        .onFailure(notifyError)
        .onSuccess(() => {
          reload(estimatesFilters)
        })
        .run(toLock.id, {
          editable_state: "closed",
        })
    },
    [estimatesFilters, reload, updateEstimate]
  )

  const onEstimateUnlock = useCallback(
    (toUnlock) => {
      updateEstimate
        .onFailure(notifyError)
        .onSuccess(() => {
          reload(estimatesFilters)
        })
        .run(toUnlock.id, {
          editable_state: "open",
        })
    },
    [estimatesFilters, reload, updateEstimate]
  )

  const openClone = useCallback(
    (estimate) => {
      createModalActions.open({
        title: estimate.title,
        customer: estimate.customer,
        cloneId: estimate.id,
      })
    },
    [createModalActions]
  )

  const downloadEstimatePDF = useCallback(
    (estimate) => {
      exportPDF
        .onFailure(notifyError)
        .onSuccess((docBinary) => {
          if (docBinary !== null) {
            saveAs(docBinary, `${estimate.code}.pdf`)
          }
        })
        .run(estimate.id, estimate.documents[0])
    },
    [exportPDF]
  )

  const columns = useMemo(
    () => [
      {
        label: t("field:estimate.code"),
        name: "code",
        size: 2,
        render: (selectedDatum, colName, datum) => {
          return (
            <div className="d-flex flex-column h-100 w-100 align-items-start justify-content-center">
              <p className={classNames(S["prevent-overflow"], "m-0")}>
                <WpLink to={`/estimates/${datum.id}`}>{selectedDatum}</WpLink>
              </p>
              {datum.archived && (
                <span className="badge badge-separator mt-2">{t("enums:estimate_state.archived_estimate")}</span>
              )}
              {datum.editable_state === "closed" && !datum.archived && (
                <span className="badge badge-secondary mt-2">{t("enums:estimate_state.locked_estimate")}</span>
              )}
            </div>
          )
        },
      },
      {
        label: t("field:estimate.title"),
        name: "title",
        size: 3,
        render: (selectedDatum, colName, datum) => {
          return (
            <div className="d-flex flex-column h-100 w-100 align-items-start justify-content-center">
              <p className={classNames(S["prevent-overflow"], "m-0")}>{selectedDatum}</p>
              <div>
                <TagCollection tags={datum.tags_data} className="mr-2 my-1" />
              </div>
            </div>
          )
        },
      },
      {
        label: t("field:estimate.emission_date"),
        name: "emission_date",
        render: (selectedDatum, colName, datum) => {
          return selectedDatum ? dayjs(selectedDatum).format("DD-MM-YYYY") : ""
        },
        size: 2,
      },
      {
        label: t("field:estimate.price"),
        name: "price",
        render: (selectedDatum, colName, datum) => {
          return <div className="text-right">{getCurrencyFormatter(datum).format(selectedDatum)}</div>
        },
        size: 2,
      },
      {
        label: t("field:estimate.drafting_state"),
        name: "drafting_state",
        size: 1,
        render: (selectedDatum, colName, datum) => {
          return <div>{t("enums:drafting_state." + selectedDatum)}</div>
        },
      },
      {
        label: t("actions"),
        name: "actions",
        size: 2,
        render: (selectedDatum, colName, datum) => {
          return (
            <div className="d-flex flex-row justify-content-between align-items-center w-100">
              <WpLink className={"action-icon-container-primary-hover"} to={`/estimates/${datum.id}/tasks`}>
                {!datum.readonly && <Icon name="edit" title={t("action:edit_estimate")} className="pointer text-dark" />}
                {datum.readonly && <Icon name="view" title={t("action:view_estimate")} className="pointer text-dark" />}
              </WpLink>
              <div className={"action-icon-container-primary-hover"}>
                {exportingPdfs[datum.id] ? (
                  <Spinner color="primary" style={{ height: 20, width: 20 }} />
                ) : (
                  <Icon
                    className={classNames("pointer text-dark", {
                      "icon-disabled": datum.documents.length === 0,
                    })}
                    name="pdf"
                    title={t("action:pdf_export")}
                    onClick={() => downloadEstimatePDF(datum)}
                  />
                )}
              </div>
              <div className={"action-icon-container-primary-hover"}>
                <Icon
                  className={classNames("pointer text-dark", {
                    "icon-disabled": datum.documents.length === 0,
                  })}
                  name="read-doc-online"
                  title={t("action:pdf_read_online")}
                  onClick={() => {
                    const id = datum.id
                    const docId = datum.documents[0]
                    window
                      .open(`${PDF_BASE_URL}/estimate-document/html/${id}/${docId}?t=Token ${token}&org=${org.id}`, "_blank")
                      .focus()
                  }}
                />
              </div>

              <div className={"action-icon-container-primary-hover"}>
                <Icon
                  name="duplicate"
                  title={t("action:duplicate")}
                  className="pointer text-dark"
                  onClick={() => openClone(datum)}
                />
              </div>
              <div className={"action-icon-container-primary-hover"}>
                <UncontrolledDropdown>
                  <DropdownToggle caret={false} tag={"span"}>
                    <Icon
                      role="button"
                      name="vdots"
                      className="text-dark pointer"
                      title={t("action:other_options")}
                      placement="right"
                    />
                  </DropdownToggle>
                  <DropdownMenu right modifiers={{ offset: { offset: "0, 12" } }} className="border-primary">
                    <DropdownItemCreateInteraction
                      disabled={!datum.customer}
                      initialValues={{
                        estimate: datum.id,
                        customer: datum.customer,
                      }}
                      lockedFields={["estimate"]}
                    />
                    <Separator className="my-0 mx-4 border-primary" />
                    {!datum.archived && (
                      <DropdownItem className={classNames("text-capitalize px-0", "dropdown-item-primary-active")}>
                        <div className="d-flex flex-row align-items-center mx-4" onClick={() => onEstimateArchive(datum)}>
                          <Icon name="archive" className={classNames("pointer mr-4")} />
                          <span>{t("action:archive")}</span>
                        </div>
                      </DropdownItem>
                    )}
                    {datum.archived && (
                      <DropdownItem className={classNames("text-capitalize px-0", "dropdown-item-primary-active")}>
                        <div className="d-flex flex-row align-items-center mx-4" onClick={() => onEstimateRestore(datum)}>
                          <Icon name="restore_archive" className={classNames("pointer mr-4")} />
                          <span>{t("action:restore")}</span>
                        </div>
                      </DropdownItem>
                    )}
                    <Separator className="my-0 mx-4 border-primary" />

                    {!datum.archived && (
                      <>
                        {datum.editable_state === "open" && (
                          <DropdownItem className={classNames("text-capitalize px-0", "dropdown-item-primary-active")}>
                            <div className="d-flex flex-row align-items-center mx-4" onClick={() => onEstimateLock(datum)}>
                              <Icon name="lock" className={classNames("pointer mr-4")} />
                              <span>{t("action:lock")}</span>
                            </div>
                          </DropdownItem>
                        )}
                        {datum.editable_state === "closed" && (
                          <DropdownItem className={classNames("text-capitalize px-0", "dropdown-item-primary-active")}>
                            <div className="d-flex flex-row align-items-center mx-4" onClick={() => onEstimateUnlock(datum)}>
                              <Icon name="unlock" className={classNames("pointer mr-4")} />
                              <span>{t("action:unlock")}</span>
                            </div>
                          </DropdownItem>
                        )}
                        <Separator className="my-0 mx-4 border-primary" />
                      </>
                    )}

                    <DropdownItem className={"text-capitalize px-0 dropdown-item-primary-active"}>
                      <div
                        className="d-flex flex-row align-items-center mx-4"
                        onClick={() => {
                          setToDelete(datum)
                          setConfirmModalOpen(true)
                        }}
                      >
                        <Icon name="delete" className="pointer mr-4" />
                        <span>{t("action:delete")}</span>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          )
        },
      },
    ],
    [
      t,
      exportingPdfs,
      downloadEstimatePDF,
      token,
      org.id,
      openClone,
      onEstimateArchive,
      onEstimateRestore,
      onEstimateLock,
      onEstimateUnlock,
    ]
  )

  return (
    <Layout>
      {estimates === null && <Loader />}
      {estimates !== null && (
        <div className="container-fluid pt-8 px-page pb-page-bottom">
          <h1 className="text-primary font-weight-semibold">{t("estimate_list.title")}</h1>

          <div className="d-flex flex-column mt-4 mb-7">
            <div className="d-flex flex-row align-items-center justify-content-between mt-3 ml-3">
              <div className="d-flex flex-row">
                <div className="d-flex flex-column pr-5">
                  <span className="mr-3">{t("estimate_list.filter_code")}</span>
                  <WpInput
                    medium
                    placeholder={t("field:estimate.code")}
                    value={code}
                    style={{ width: 150 }}
                    onChange={(e) =>
                      setDebouncedQueryParams({
                        code: e.target.value,
                        page: 1,
                      })
                    }
                    className="pl-3"
                  />
                </div>
                <div className="d-flex flex-column pr-5">
                  <span className="mr-3">{t("estimate_list.filter_title")}</span>
                  <WpInput
                    medium
                    placeholder={t("field:estimate.title")}
                    style={{ width: 150 }}
                    value={title}
                    onChange={(e) =>
                      setDebouncedQueryParams({
                        title: e.target.value,
                        page: 1,
                      })
                    }
                    className="pl-3"
                  />
                </div>
                <div className="d-flex flex-column pr-5">
                  <span className="mr-3">{t("estimate_list.filter_kind")}</span>
                  <Dropdown
                    value={kind}
                    className="px-3 py-2"
                    options={[
                      {
                        value: "sviluppo",
                        label: t("enums:estimate_kind.sviluppo"),
                      },
                      {
                        value: "manutenzione",
                        label: t("enums:estimate_kind.manutenzione"),
                      },
                      { value: "all", label: t("enums:estimate_state.all") },
                    ]}
                    onChange={(val) => {
                      setQueryParams({ kind: val, page: 1 })
                    }}
                    itemWidth={200}
                    style={{ width: 200 }}
                  />
                </div>
                <div className="d-flex flex-column pr-5">
                  <span className="mr-3">{t("estimate_list.filter_status")}</span>
                  <Dropdown
                    value={status}
                    className="px-3 py-2"
                    options={[
                      {
                        value: "draft",
                        label: t("enums:drafting_state.draft"),
                      },
                      {
                        value: "ready",
                        label: t("enums:drafting_state.ready"),
                      },
                      {
                        value: "submitted",
                        label: t("enums:drafting_state.submitted"),
                      },
                      {
                        value: "approved",
                        label: t("enums:drafting_state.approved"),
                      },
                      {
                        value: "rejected",
                        label: t("enums:drafting_state.rejected"),
                      },
                      {
                        value: "expired",
                        label: t("enums:drafting_state.expired"),
                      },
                      { value: "all", label: t("enums:estimate_state.all") },
                    ]}
                    onChange={(val) => {
                      setQueryParams({ status: val, page: 1 })
                    }}
                    itemWidth={200}
                    style={{ width: 200 }}
                  />
                </div>
                <div className="d-flex flex-column pr-5">
                  <span className="mr-3">{t("estimate_list.filter_archived")}</span>
                  <Dropdown
                    value={archived}
                    className="px-3 py-2"
                    options={[
                      {
                        value: "active",
                        label: t("enums:estimate_state.active"),
                      },
                      {
                        value: "archived",
                        label: t("enums:estimate_state.archived"),
                      },
                      { value: "all", label: t("enums:estimate_state.all") },
                    ]}
                    onChange={(val) => {
                      setQueryParams({ archived: val, page: 1 })
                    }}
                    itemWidth={200}
                    style={{ width: 200 }}
                  />
                </div>
                <div className="d-flex flex-column pr-5">
                  <span className="mr-3">{t("estimate_list.filter_customer")}</span>
                  <CustomerSelector
                    orgId={org.id}
                    customerId={customer}
                    enableNone
                    caption={
                      !customer
                        ? () => <span className="text-muted">{t("estimate_list.filter_customer_placeholder")}</span>
                        : undefined
                    }
                    onChange={(nextCustomerId) => setQueryParams({ customer: nextCustomerId })}
                    itemWidth={200}
                    style={{ width: 200 }}
                  />
                </div>
              </div>
              <div>
                <span className="ml-2">
                  {t("estimate_list.estimate_count", {
                    filtered: count,
                    count: fullCount,
                    context: count !== 1 ? "many" : "one",
                  })}
                </span>
              </div>
            </div>
          </div>

          {/*Something to show*/}
          {count !== 0 && (
            <>
              <LongBorder topBar="toolbar" />

              <Table columns={columns} data={estimates} className="pb-8" highlightRows={[]} />

              <Pagination
                current={debPage}
                pageCount={pageCount}
                goToPage={(page) => {
                  setQueryParams({ page })
                  window.scrollTo(0, 10)
                }}
                hasNext={hasNext}
                hasPrev={hasPrev}
              />
            </>
          )}

          {/*Something to show but filtered so empty or Nothing to show but archived and not loading*/}
          {((fullCount !== 0 && count === 0) || (fullCount === 0 && status && status === "archived")) && !loading && (
            <div className="d-flex flex-column text-primary align-items-start">
              <span className="pb-4">{t("estimate_list.empty_estimates_filter")}</span>
              <MeasureContext as="img" src={EmptyFilterImage} alt="" bottom={0} style={{ maxHeight: 417, height: "100%" }}>
                {(ref) => (
                  <>
                    <Button
                      onClick={() => {
                        setQueryParams({
                          title: "",
                          code: "",
                          tag: "",
                          page: 1,
                        })
                      }}
                      size="sm"
                      color="dark"
                    >
                      {t("action:select_all")}
                    </Button>
                    <div ref={ref}></div>
                  </>
                )}
              </MeasureContext>
            </div>
          )}

          {/*Nothing to show but active or all*/}
          {fullCount === 0 && ((status && status !== "archived") || status === undefined) && (
            <div className="d-flex flex-column text-primary mt-5">
              <span className="pb-4">{t("estimate_list.empty_estimates")}</span>
              <MeasureContext as="img" src={EmptyImage} alt="" bottom={0} maxHeight={400} className="h-100">
                {(ref) => (
                  <div
                    className="d-flex flex-row align-items-center position-relative pointer"
                    onClick={() => createModalActions.open()}
                  >
                    <Button color={"primary"} size="lg" rounded>
                      <Icon name="plus" className="pointer" />
                    </Button>
                    <span className="pl-3" ref={ref}>
                      {t("estimate_list.create_estimate")}
                    </span>
                  </div>
                )}
              </MeasureContext>
            </div>
          )}
        </div>
      )}

      {createModal.value && (
        <CreateEstimateModal
          mode={createModal.value && createModal.value !== "dummy" ? "duplicate" : "create"}
          cloneEstimate={createModal.value === "dummy" ? undefined : createModal.value}
          toggle={createModalActions.toggle}
          isOpen={createModal.isOpen}
          onSave={onEstimateCreation}
          onClosed={createModalActions.onClose}
        />
      )}

      {createTemplateModal.value && (
        <CreateEstimateTemplateModal
          toggle={createTemplateModalActions.toggle}
          isOpen={createTemplateModal.isOpen}
          onSave={onEstimateTemplateCreation}
          onClosed={createTemplateModalActions.onClose}
        />
      )}

      <ConfirmDeleteModal toggle={onDelete} isOpen={isConfirmModalOpen} onConfirm={onEstimateDeletion} item={toDelete?.title} />

      <Layout.Toolbar title={t("estimate_list.toolbar_title")}>
        <Layout.Toolbar.Icon
          text={t("action:new_estimate_blank")}
          svgIcon={<Icon name="new-estimate" />}
          title={t("action:new_estimate_blank")}
          onClick={() => createModalActions.open("dummy")}
        />
        <Layout.Toolbar.Divider />
        <Layout.Toolbar.Icon
          text={t("action:new_estimate_template")}
          svgIcon={<Icon name="new_estimate_template" />}
          title={t("action:new_estimate_template")}
          onClick={() => createTemplateModalActions.open("dummy")}
        />
      </Layout.Toolbar>
      <Layout.FirstLevelNavi>
        <EstimateLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}
