import { truncate } from "lodash"
import React from "react"
import useWpHistoryMethods from "../hooks/useWpHistoryMethods"

const ROW_HEIGHT = 60
const ROW_GUTTER = 20
const COL_GUTTER = 0
const PADDING = 10

function mapIndexes(lst) {
  const out = {}
  for (const [i, elem] of lst.entries()) {
    out[elem.id] = i
  }
  return out
}

const requestStateColor = {
  pending: "var(--gray-bg)",
  "waiting-estimate": "var(--warning)",
  completed: "var(--success)",
  dismissed: "var(--danger)",
}

const estimateStateColor = {
  draft: "var(--gray-bg)",
  ready: "var(--info)",
  submitted: "var(--warning)",
  approved: "var(--success)",
  rejected: "var(--danger)",
  expired: "var(--danger)",
}

const projectStateColor = {
  open: "var(--warning)",
  closed: "var(--info)",
  billed: "#ccc",
  on_hold: "var(--gray-bg)",
  canceled: "var(--danger)",
}

export function FlowGraph({ width, nodes, arcs, current }) {
  const requests = [],
    estimates = [],
    projects = []

  const history = useWpHistoryMethods()

  for (let key in nodes) {
    switch (key[0]) {
      case "r":
        requests.push(nodes[key])
        break
      case "e":
        estimates.push(nodes[key])
        break
      case "p":
        projects.push(nodes[key])
        break
      default:
      // do nothing
    }
  }

  const rows = Math.max(requests.length, estimates.length, projects.length)

  const cellWidth = Math.round((width - 2 * PADDING - 4 * COL_GUTTER) / 5)
  const cellHeight = ROW_HEIGHT

  const frameHeight =
    (cellHeight + ROW_GUTTER) * rows - ROW_GUTTER + PADDING * 2
  const frameWidth = width

  const requestSpace = (frameHeight - 2 * PADDING) / requests.length
  const estimateSpace = (frameHeight - 2 * PADDING) / estimates.length
  const projectSpace = (frameHeight - 2 * PADDING) / projects.length

  const requestX = PADDING
  const estimateX = PADDING + cellWidth * 2 + COL_GUTTER * 2
  const projectX = PADDING + cellWidth * 4 + COL_GUTTER * 4

  const x = { r: requestX, e: estimateX, p: projectX }
  const dy = { r: requestSpace, e: estimateSpace, p: projectSpace }
  const d = {
    r: mapIndexes(requests),
    e: mapIndexes(estimates),
    p: mapIndexes(projects),
  }

  return (
    <svg width={frameWidth} height={frameHeight}>
      <defs>
        <marker
          id="arrowhead"
          markerWidth="10"
          markerHeight="7"
          refX="0"
          refY="3.5"
          orient="auto"
        >
          <polygon points="0 0, 10 3.5, 0 7" />
        </marker>
      </defs>
      {requests.map((request, i) => {
        const marginTop = (requestSpace - cellHeight) / 2
        const top = PADDING + requestSpace * i + marginTop
        const left = requestX
        return (
          <g key={request.id} transform={`translate(${left} ${top})`}>
            <rect
              fill={requestStateColor[request.status]}
              x={0}
              y={0}
              width={cellWidth}
              height={cellHeight}
              className="pointer"
              onClick={() => {
                history.push(
                  `/customers/${request.customer}/requests/${request.id}`
                )
              }}
              stroke="#000"
              strokeWidth={current === `r${request.id}` ? 1.5 : 0}
            />
            <text
              x={10}
              y={cellHeight / 2}
              fontSize={10}
              dy={-4}
              style={{ pointerEvents: "none" }}
            >
              {request.code}
            </text>
            <text
              x={10}
              y={cellHeight / 2}
              fontSize={14}
              dy={13}
              style={{ pointerEvents: "none" }}
            >
              {truncate(request.title, { length: 25 })}
            </text>
          </g>
        )
      })}
      {estimates.map((estimate, i) => {
        const marginTop = (estimateSpace - cellHeight) / 2
        const top = PADDING + estimateSpace * i + marginTop
        const left = estimateX
        return (
          <g transform={`translate(${left} ${top})`} key={estimate.id}>
            <rect
              fill={estimateStateColor[estimate.drafting_state]}
              x={0}
              y={0}
              width={cellWidth}
              height={cellHeight}
              className="pointer"
              onClick={() => {
                history.push(`/estimates/${estimate.id}`)
              }}
              stroke="#000"
              strokeWidth={current === `e${estimate.id}` ? 1.5 : 0}
            />
            <text
              x={10}
              y={cellHeight / 2}
              fontSize={10}
              dy={-4}
              style={{ pointerEvents: "none" }}
            >
              {estimate.code}
            </text>
            <text
              x={10}
              y={cellHeight / 2}
              fontSize={14}
              dy={13}
              style={{ pointerEvents: "none" }}
            >
              {truncate(estimate.title, { length: 25 })}
            </text>
          </g>
        )
      })}
      {projects.map((project, i) => {
        const marginTop = (projectSpace - cellHeight) / 2
        const top = PADDING + projectSpace * i + marginTop
        const left = projectX
        return (
          <g transform={`translate(${left} ${top})`} key={project.id}>
            <rect
              fill={projectStateColor[project.status]}
              x={0}
              y={0}
              width={cellWidth}
              height={cellHeight}
              className="pointer"
              onClick={() => {
                history.push(`/projects/${project.id}`)
              }}
              stroke="#000"
              strokeWidth={current === `p${project.id}` ? 1.5 : 0}
            />
            <text
              x={10}
              y={cellHeight / 2}
              fontSize={10}
              dy={-4}
              style={{ pointerEvents: "none" }}
            >
              {project.code}
            </text>
            <text
              x={10}
              y={cellHeight / 2}
              fontSize={14}
              dy={13}
              style={{ pointerEvents: "none" }}
            >
              {truncate(project.title, { length: 25 })}
            </text>
          </g>
        )
      })}
      {arcs.map(([key1, key2]) => {
        const t1 = key1[0]
        const id1 = parseInt(key1.slice(1))

        const t2 = key2[0]
        const id2 = parseInt(key2.slice(1))

        const x1 = x[t1] + cellWidth
        const x2 = x[t2]
        const y1 = (d[t1][id1] + 0.5) * dy[t1] + PADDING
        const y2 = (d[t2][id2] + 0.5) * dy[t2] + PADDING
        return (
          <line
            x1={x1}
            x2={x2}
            y1={y1}
            y2={y2}
            stroke="#000"
            strokeWidth={1}
            // markerEnd="url(#arrowhead)"
          ></line>
        )
      })}
    </svg>
  )
}
