import { Field, Formik } from "formik"
import { useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import Button from "../../../components/Button"
import { EstimateLandmarks } from "../../../components/EstimateLandmarks"
import FieldCustomerSelector from "../../../components/fields/FieldCustomerSelector"
import FieldDropdown from "../../../components/fields/FieldDropdown"
import FieldInput from "../../../components/fields/FieldInput"
import FieldTextarea from "../../../components/fields/FieldTextarea"
import FieldYesNo from "../../../components/fields/FieldYesNo"
import Layout from "../../../components/Layout"
import Loader from "../../../components/Loader/Loader"
import { TagCollection } from "../../../components/TagCollection"
import { CurrentEstimateStateContext } from "../../../context"
import { usePriceLists } from "../../../hooks/priceList"
import { getCurrencyFormatter } from "../../../hooks/useCurrencyFormatter"
import useWpHistoryMethods from "../../../hooks/useWpHistoryMethods"
import EstimateBottomBarContent from "../EstimateBottomBarContent"
import EstimateTabBarContent from "../EstimateTabBarContent"

function getInitialValues(estimate) {
  let customerStr = ""
  if (estimate.customer_data) {
    customerStr = estimate.customer_data.name
    if (estimate.customer_data.code) {
      customerStr += ` (${estimate.customer_data.code})`
    }
  }
  return {
    title: estimate.title,
    code: estimate.code,
    description: estimate.description,
    document_header: estimate.document_header,
    drafting_state: estimate.drafting_state,
    price_list: estimate.price_list,
    emission_date: estimate.emission_date ?? "",
    customer: estimate.customer,
    customer_name: customerStr,
    rejection_reason: estimate.rejection_reason,
    expire_date: estimate.expire_date ?? "",
    approval_date: estimate.approval_date ?? "",
    is_contest: estimate.is_contest ?? false,
    new_customer: estimate.new_customer ?? "",
    kind: estimate.kind ?? "sviluppo",
  }
}

export default function EstimateInfoStatic() {
  const { t } = useTranslation(["translation", "tab", "action"])

  const [{ estimate }] = useContext(CurrentEstimateStateContext)

  const [{ data: priceLists }] = usePriceLists()

  const draftingStateOptions = useMemo(() => {
    const opts = ["draft", "ready", "submitted", "approved", "rejected", "expired"]

    return opts.map((opt) => ({
      value: opt,
      label: t("enums:drafting_state." + opt),
    }))
  }, [t])

  const rejectionOptions = useMemo(() => {
    const opts = ["price", "content", "customer_internal", "unknown"]

    return opts.map((opt) => ({
      value: opt,
      label: t("enums:rejection_reason." + opt),
    }))
  }, [t])

  const kindOptions = useMemo(() => {
    const opts = ["sviluppo", "manutenzione", "non_specificato"]

    return opts.map((opt) => ({
      value: opt,
      label: t("enums:estimate_kind." + opt),
    }))
  }, [t])

  const history = useWpHistoryMethods()

  return (
    <Layout>
      <div className="container pt-5 px-half-page pb-page-bottom">
        {!estimate && <Loader />}
        {estimate && (
          <Formik initialValues={getInitialValues(estimate)} initialStatus={getInitialValues(estimate)}>
            {(formik) => {
              return (
                <form autoComplete="off">
                  <div className="d-flex flex-column px-page">
                    <h2 className="text-center text-uppercase mb-5 font-weight-semibold">{t("estimate_info.estimate_data")}</h2>
                    <div className="d-flex flex-row justify-content-center mb-6">
                      <div className="flex-1">
                        <Field
                          name="title"
                          label={t("field:estimate.title")}
                          placeholder={t("field:estimate.placeholder.title")}
                          component={FieldInput}
                          readOnly={true}
                          readOnlyPlaceholder="--"
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-center mb-6">
                      <div className="flex-1 pr-5">
                        <Field
                          name="code"
                          label={t("field:estimate.code")}
                          placeholder={t("field:estimate.placeholder.code")}
                          component={FieldInput}
                          readOnly={true}
                          readOnlyPlaceholder="--"
                        />
                      </div>
                      <div className="flex-1 pl-5">
                        <Field
                          name="price_list"
                          label={t("field:estimate.price_list")}
                          component={FieldDropdown}
                          readOnly={true}
                          options={
                            priceLists?.map((priceList) => ({
                              value: priceList.id,
                              label: priceList.title + " (" + getCurrencyFormatter(priceList.currency).currencySymbol + ")",
                            })) ?? []
                          }
                          className="border-radius-xl px-4 py-3"
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-center mb-6">
                      <div className="flex-1 pr-5">
                        <div className="d-flex flex-row justify-content-center mb-6">
                          <div className="flex-1 pr-5">
                            <Field
                              name="emission_date"
                              label={t("field:estimate.emission_date")}
                              component={FieldInput}
                              type="date"
                              readOnly={true}
                            />
                          </div>
                          <div className="flex-1 pl-5">
                            <Field
                              name="expire_date"
                              label={t("field:estimate.expire_date")}
                              component={FieldInput}
                              type="date"
                              readOnly={true}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex-1 pl-5 d-flex flex-row">
                        <div className="flex-1 pr-5">
                          <Field
                            name="kind"
                            label={t("field:estimate.kind")}
                            component={FieldDropdown}
                            type="date"
                            readOnly={true}
                            options={kindOptions}
                            className="border-radius-xl px-4 py-3"
                          />
                        </div>
                        <div style={{ flex: "0 0" }}>
                          <Field
                            name="is_contest"
                            label={t("field:project.is_contest")}
                            component={FieldYesNo}
                            containerClassName="justify-content-center align-items-center"
                            readOnly={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex-1 mb-6">
                      <Field
                        name="description"
                        auto
                        rows={4}
                        label={t("field:estimate.description")}
                        placeholder={t("field:estimate.placeholder.description")}
                        component={FieldTextarea}
                        readOnly={true}
                        readOnlyPlaceholder="--"
                      />
                    </div>
                    <div className="d-flex flex-row justify-content-center">
                      <div className="flex-1 pr-5">
                        <Field
                          name="drafting_state"
                          label={t("field:estimate.drafting_state")}
                          component={FieldDropdown}
                          readOnly={true}
                          options={draftingStateOptions}
                          className="border-radius-xl px-4 py-3"
                        />
                      </div>
                      <div className="flex-1 pl-5">
                        {formik.values.drafting_state === "rejected" && (
                          <Field
                            name="rejection_reason"
                            label={t("field:estimate.rejection_reason")}
                            component={FieldDropdown}
                            readOnly={true}
                            options={rejectionOptions}
                            className="border-radius-xl px-4 py-3"
                          />
                        )}
                        {formik.values.drafting_state === "approved" && (
                          <Field
                            name="approval_date"
                            label={t("field:estimate.approval_date")}
                            component={FieldInput}
                            type="date"
                            readOnly={true}
                          />
                        )}
                      </div>
                    </div>

                    <h2 className="text-center text-uppercase mt-8 mb-5 font-weight-semibold">
                      {t("estimate_info.customer_data")}
                    </h2>
                    <div className="d-flex flex-row justify-content-center">
                      <div className="flex-1 pr-5">
                        <Field
                          name="customer"
                          label={t("field:estimate.customer_name")}
                          placeholder={t("field:estimate.placeholder.customer_name")}
                          component={FieldCustomerSelector}
                          readOnly={true}
                          readOnlyPlaceholder="--"
                          caption={formik.values.customer_name}
                          controlClassName="px-4 py-3 border-radius-xl"
                          enableNone
                        />
                      </div>
                      <div className="pl-5">
                        <Field
                          name="new_customer"
                          label={t("field:incoming_request.new_customer")}
                          component={FieldYesNo}
                          readOnly
                        />
                      </div>
                    </div>

                    <h2 className="text-center text-uppercase mt-8 mb-5 font-weight-semibold">{t("estimate_info.tags")}</h2>
                    <div className="text-center">
                      <TagCollection tags={estimate.tags_data} className="mr-2" />
                    </div>
                  </div>

                  <div className="d-fle flex-column">
                    <div className="d-flex flex-row"></div>
                  </div>
                </form>
              )
            }}
          </Formik>
        )}
        <div className="mt-9 text-center">
          <Button
            onClick={() => {
              history.push(`/estimates/${estimate.id}/edit`)
            }}
          >
            {t("action:edit")}
          </Button>
        </div>
      </div>
      <Layout.TabBar>
        <EstimateTabBarContent />
      </Layout.TabBar>
      <Layout.BottomBar className="border-top border-separator">
        <EstimateBottomBarContent />
      </Layout.BottomBar>
      <Layout.FirstLevelNavi>
        <EstimateLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}
