import React from "react"
import useQueryParam from "magik-react-hooks/useRouterQueryParam"
import { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { CustomerSelectorMultiple } from "../components/CustomerSelector/CustomerSelector"
import Dropdown from "../components/Dropdown"

const EMPTY_LIST = []
const KINDS_LIST = ["development", "maintenance", "commercial", "generic"]

export function useAnalysisFilters() {
  const { t } = useTranslation()

  const [customers, setCustomers] = useQueryParam("customers", EMPTY_LIST, {
    encode: (lst) => lst.join(","),
    decode: (str) => str.split(",").map((n) => parseInt(n, 10)),
  })
  const [projectKinds, setProjectKinds] = useQueryParam("project-kinds", KINDS_LIST, {
    encode: (lst) => lst.join(","),
    decode: (str) => str.split(","),
  })

  const kindOptions = useMemo(() => {
    const opts = KINDS_LIST

    return opts.map((opt) => ({
      value: opt,
      label: t("enums:project_kind." + opt),
    }))
  }, [t])

  const filters = useMemo(() => {
    const f = {}
    if (customers.length > 0) {
      f.customer__in = customers
    }
    if (projectKinds.length > 0) {
      f.kind__in = projectKinds
    }
    return f
  }, [customers, projectKinds])

  const render = useCallback(() => {
    return (
      <>
        <span className="mr-4">{t("overview.filter_customer")}</span>
        <CustomerSelectorMultiple customersId={customers} onChange={setCustomers} emptyCaption={t("all")} />
        <span class="ml-6 mr-4">{t("overview.filter_project_kind")}</span>
        <Dropdown
          value={projectKinds}
          className="px-3 py-2"
          options={kindOptions}
          onChange={(val) => {
            setProjectKinds(val)
          }}
          itemWidth={160}
          style={{ width: 160 }}
        />
      </>
    )
  }, [customers, kindOptions, projectKinds, setCustomers, setProjectKinds, t])

  return { filters, renderFilters: render }
}
