import classNames from "classnames"
import dayjs from "dayjs"
import { isNil, range } from "lodash"
import useQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import React from "react"
import { useTranslation } from "react-i18next"
import { IndicatorsLandmarks } from "../../components/IndicatorsLandmarks"
import Layout from "../../components/Layout"
import WpInput from "../../components/WpInput"
import { useBusinessMonitor } from "../../hooks/businessMonitor"
import { useAnalysisFilters } from "../../hooks/useAnalysisFilters"
import S from "./style.module.scss"

const MONTHS = [
  "gennaio",
  "febbraio",
  "marzo",
  "aprile",
  "maggio",
  "giugno",
  "luglio",
  "agosto",
  "settembre",
  "ottobre",
  "novembre",
  "dicembre",
]

export function BusinessMonitoring() {
  const { t } = useTranslation()
  const { filters, renderFilters } = useAnalysisFilters()

  const [{ fromDate, toDate }, , { fromDate: debFromDate, toDate: debToDate }, setDebQueryParams] = useQueryParams()

  const [{ data }] = useBusinessMonitor(debFromDate, debToDate, filters)

  const numOfMonths =
    debFromDate && debToDate
      ? dayjs(debToDate).endOf("month").add(1, "day").diff(dayjs(debFromDate).startOf("month"), "month")
      : 0

  const iterDateBase = debFromDate ? dayjs(fromDate).startOf("month") : null
  const iterBaseMonth = iterDateBase?.get("month")
  const iterBaseYear = iterDateBase?.get("year")

  return (
    <Layout className="flex-1 d-flex flex-column justify-content-start align-items-stretch" displayRawContent>
      <div className={S.outer}>
        <div className={classNames("px-page pt-8", S["sticky-left"])}>
          <h1 className="text-primary font-weight-semibold">{t("business.title")}</h1>
          <div className="d-flex flex-row justify-content-start align-items-start">
            <div
              className="d-flex flex-row justify-content-start align-items-center flex-1 flex-wrap"
              style={{ rowGap: 16, columnGap: 32 }}
            >
              <div style={{ whiteSpace: "nowrap" }}>
                <span className="mr-3">{t("business.period_filter_from")}</span>
                <WpInput
                  medium
                  type="date"
                  value={fromDate}
                  onChange={(e) => setDebQueryParams({ fromDate: e.target.value, page: 1 })}
                />
                <span className="mx-3">{t("business.period_filter_to")}</span>
                <WpInput
                  medium
                  type="date"
                  value={toDate}
                  onChange={(e) => setDebQueryParams({ toDate: e.target.value, page: 1 })}
                />
              </div>
              <div style={{ whiteSpace: "nowrap" }}>{renderFilters()}</div>
            </div>
          </div>
        </div>
        {!(!!numOfMonths && numOfMonths > 0 && numOfMonths < 24) && (
          <div className="px-page pt-8">
            <i>
              Impossibile mostrare questo intervallo, verificare che le date siano ordinate correttamente e che il periodo non
              superi i 24 mesi
            </i>
          </div>
        )}
        {!!numOfMonths && numOfMonths > 0 && numOfMonths < 24 && (
          <table
            className="table table-sm table-striped mt-5"
            style={{
              tableLayout: "fixed",
              borderCollapse: "separate",
              borderSpacing: 0,
            }}
          >
            <thead>
              <tr>
                <th className={classNames("bg-white", S["sticky-left"], "border-right")} style={{ width: 300 }}>
                  &nbsp;
                </th>
                {range(numOfMonths).map((n) => (
                  <React.Fragment key={n}>
                    <th colSpan={3} style={{ width: 300 }} className="text-center border-right">
                      {t(MONTHS[(iterBaseMonth + n) % 12]) + " " + (iterBaseYear + Math.floor((iterBaseMonth + n) / 12))}
                    </th>
                  </React.Fragment>
                ))}
              </tr>
              <tr>
                <th className={classNames(S["sticky-left"], "border-right", "bg-white")}>Oggetto</th>
                {range(numOfMonths).map((n) => (
                  <React.Fragment key={n}>
                    <th style={{ whiteSpace: "nowrap", width: 100 }} className="border-right">
                      {t("business.billed")}
                    </th>
                    <th style={{ whiteSpace: "nowrap", width: 100 }} className="border-right">
                      {t("business.to_bill")}
                    </th>
                    <th style={{ whiteSpace: "nowrap", width: 100 }} className="border-right">
                      {t("business.maybe_bill")}
                    </th>
                  </React.Fragment>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.map((item, i) => {
                return (
                  <tr key={item.kind + item.id}>
                    <th
                      style={{
                        whiteSpace: "nowrap",
                        background: i % 2 === 1 ? "white" : "#f5f5f5",
                      }}
                      className={classNames(S["sticky-left"], "border-right")}
                    >
                      {item.name}
                    </th>
                    {range(numOfMonths).map((n) => (
                      <React.Fragment key={n}>
                        <td style={{ whiteSpace: "nowrap" }} className="border-right text-right">
                          <div className="px-2">{formatEuro(item.billed[n])}</div>
                        </td>
                        <td style={{ whiteSpace: "nowrap" }} className="border-right text-right">
                          <div className="px-2">{formatEuro(item.to_bill[n])}</div>
                        </td>
                        <td style={{ whiteSpace: "nowrap" }} className="border-right text-right">
                          <div className="px-2">{formatEuro(item.maybe_bill[n])}</div>
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                )
              })}
              <tr className="font-weight-bold">
                <td
                  style={{ whiteSpace: "nowrap", background: (data?.length ?? 0) % 2 === 1 ? "white" : "#f5f5f5" }}
                  className={classNames(S["sticky-left"], "border-right")}
                >
                  {t("total")}
                </td>
                {range(numOfMonths).map((n) => (
                  <React.Fragment key={n}>
                    <td style={{ whiteSpace: "nowrap" }} className="border-right text-right">
                      <div className="px-2">{formatEuro(data?.reduce((acc, item) => acc + parseFloat(item.billed[n]), 0))}</div>
                    </td>
                    <td style={{ whiteSpace: "nowrap" }} className="border-right text-right">
                      <div className="px-2">
                        {formatEuro(data?.reduce((acc, item) => acc + parseFloat(item.to_bill[n]), 0))}
                      </div>
                    </td>
                    <td style={{ whiteSpace: "nowrap" }} className="border-right text-right">
                      <div className="px-2">
                        {formatEuro(data?.reduce((acc, item) => acc + parseFloat(item.maybe_bill[n]), 0))}
                      </div>
                    </td>
                  </React.Fragment>
                ))}
              </tr>
            </tbody>
          </table>
        )}
      </div>
      <Layout.FirstLevelNavi>
        <IndicatorsLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

function formatEuro(value) {
  if (isNil(value)) {
    return <span>-</span>
  } else if (value === 0) {
    return <span></span>
  } else {
    return <span>{parseFloat(value).toFixed(2) + " €"}</span>
  }
}
