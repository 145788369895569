import React, { useCallback, useEffect, useState } from "react"
import S from "./TemplatingPopover.module.scss"
import classNames from "classnames"
import { useTranslation } from "react-i18next"

const ITEMS = [
  "estimate.id",
  "estimate.price",
  "estimate.code",
  "estimate.title",
  "estimate.updated_at|date:LL LT",
  "estimate.description",
  "estimate.drafting_state",
  "estimate.emission_date|date:LL",
  "estimate.expire_date|date:LL",
]

const ITEMS_CUSTOMER = [
  ["estimate.customer_data.name", "customer.name"],
  ["estimate.customer_data.tax_code", "customer.tax_code"],
  ["estimate.customer_data.address", "customer.address"],
  ["estimate.customer_data.house_number", "customer.house_number"],
  ["estimate.customer_data.postal_code", "customer.postal_code"],
  ["estimate.customer_data.city", "customer.city"],
  ["estimate.customer_data.province", "customer.province"],
  ["estimate.customer_data.nation", "customer.nation"],
  ["estimate.customer_data.website", "customer.website"],
  ["estimate.customer_data.phone", "customer.phone"],
  ["estimate.customer_data.info_email", "customer.info_email"],
  ["estimate.customer_data.document_header", "customer.document_header"],
]

export default function TemplatingPopover({
  onSelect,
  context = {},
  left = false,
  bottom = false,
  disabled = false,
}) {
  const [showPicker, setShowPicker] = useState(false)
  const { t } = useTranslation()

  const togglePicker = useCallback(() => {
    setShowPicker((v) => !v)
  }, [])

  useEffect(() => {
    if (showPicker) {
      const handler = (e) => {
        togglePicker()
      }
      window.addEventListener("click", handler)
      return () => window.removeEventListener("click", handler)
    }
  }, [showPicker, togglePicker])

  return (
    <div
      className={classNames(
        S["templating-popover"],
        "d-flex flex-row align-items-center"
      )}
      aria-haspopup="true"
      aria-expanded={showPicker}
      aria-label="rdw-color-picker"
      style={{ marginBottom: 6 }}
      onClick={(e) => e.stopPropagation()}
    >
      <div
        className={classNames(S["templating-button"], "rdw-option-wrapper", {
          pointer: !disabled,
          "not-allowed": disabled,
        })}
        onMouseDown={(e) => {
          e.preventDefault()
          if (disabled) {
            return
          } else {
            togglePicker()
          }
        }}
        style={{ fontFamily: "monospace" }}
      >
        {"{•}"}
      </div>
      {showPicker && (
        <div
          className={classNames(
            S["templating-popup"],
            { [S["left-picker"]]: left },
            { [S["bottom-picker"]]: bottom },
            { [S["right-picker"]]: !left },
            { [S["top-picker"]]: !bottom }
          )}
        >
          <div className={S["templating-not-clickable"]}>
            <b>Dati preventivo</b>
          </div>
          {ITEMS.map((key) => {
            let captionKey = key
            if (Array.isArray(key)) {
              key = key[0]
              captionKey = captionKey[1]
            }
            const [transKey] = captionKey.split("|")
            return (
              <div
                key={key}
                className={S["templating-clickable"]}
                onClick={(e) => {
                  setShowPicker(false)
                  onSelect(key, e)
                }}
              >
                {t(`field:${transKey}`)}
              </div>
            )
          })}
          <div className={S["templating-not-clickable"]}>
            <b>Dati cliente</b>
          </div>
          {ITEMS_CUSTOMER.map((key) => {
            let captionKey = key
            if (Array.isArray(key)) {
              key = key[0]
              captionKey = captionKey[1]
            }
            const [transKey] = captionKey.split("|")
            return (
              <div
                key={key}
                className={S["templating-clickable"]}
                onClick={(e) => {
                  setShowPicker(false)
                  onSelect(key, e)
                }}
              >
                {t(`field:${transKey}`)}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
