import dayjs from "dayjs"
import { useMemo } from "react"
import useQueryParam from "magik-react-hooks/useRouterQueryParam"

const EMPTY_LIST = []

export function useLinkToBusinessMonitor(params) {
  const [customers] = useQueryParam("customers", EMPTY_LIST)
  const [projectKinds] = useQueryParam("project-kinds", EMPTY_LIST)

  const allParamsStr = useMemo(() => {
    const from = dayjs().startOf("month").format("YYYY-MM-DD")
    const to = dayjs().endOf("year").format("YYYY-MM-DD")
    const allParams = {
      ...params,
      fromDate: from,
      toDate: to,
    }
    if (customers) {
      allParams.customers = customers
    }
    if (projectKinds) {
      allParams["project-kinds"] = projectKinds
    }
    return new URLSearchParams(allParams).toString()
  }, [customers, params, projectKinds])
  return `/business?${allParamsStr}`
}

export function useLinkToIndicators(params) {
  const [customers] = useQueryParam("customers", EMPTY_LIST)
  const [projectKinds] = useQueryParam("project-kinds", EMPTY_LIST)

  const allParamsStr = useMemo(() => {
    const from = dayjs().startOf("year").format("YYYY-MM-DD")
    const to = dayjs().endOf("year").format("YYYY-MM-DD")
    const allParams = {
      ...params,
      fromDate: from,
      toDate: to,
    }
    if (customers) {
      allParams.customers = customers
    }
    if (projectKinds) {
      allParams["project-kinds"] = projectKinds
    }
    return new URLSearchParams(allParams).toString()
  }, [customers, params, projectKinds])
  return `/indicators?${allParamsStr}`
}

export function useLinkToOverview(params) {
  const [customers] = useQueryParam("customers", EMPTY_LIST)
  const [projectKinds] = useQueryParam("project-kinds", EMPTY_LIST)

  const allParamsStr = useMemo(() => {
    const from = dayjs().startOf("month").format("YYYY-MM-DD")
    const to = dayjs().endOf("year").format("YYYY-MM-DD")
    const allParams = {
      ...params,
      fromDate: from,
      toDate: to,
    }
    if (customers) {
      allParams.customers = customers
    }
    if (projectKinds) {
      allParams["project-kinds"] = projectKinds
    }
    return new URLSearchParams(allParams).toString()
  }, [customers, params, projectKinds])
  return `/overview?${allParamsStr}`
}
