import { useMemo } from "react"
import { deps, rj, useRunRj } from "react-rocketjump"
import api from "../api"

const BusinessMonitorRj = rj({
  effectCaller: rj.configured(),
  effect: (wpAuth) => (filters) =>
    api.auth(wpAuth).get("/api/overview/business-monitor", filters),
})

export function useBusinessMonitor(fromDate, toDate, params) {
  const filters = useMemo(() => {
    if (fromDate && toDate) {
      return {
        ...params,
        from_date: fromDate,
        to_date: toDate,
      }
    } else {
      return null
    }
  }, [fromDate, params, toDate])

  return useRunRj(BusinessMonitorRj, [deps.maybe(filters)], false)
}
